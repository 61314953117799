
import { defineComponent } from "vue";
// import { find, create } from "../../api/category.js";
// import { list } from "../../api/model.js";
import { addLabelValue, tree } from "@/utils/tools";
import { pinyin } from "pinyin-pro";
import { client } from "@/client";
import { CategoryStatus, CategoryTarget, CategoryType } from "@/shared/types/CategoryData";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "category-add",
  data: () => {
    return {
      categorySelected: []  as any, //-1默认选中顶级栏目
      categoryProps: { checkStrictly: true },
      activeName: "first", //tab 默认显示第一个
      activeIndex: 0, //tab 内容默认显示第一个
      category: [] as any, //当前所有栏目
      cate: [] as any,
      modList: [] as any, //模型列表
      params: {
        //接口入参
        pid: 0,
        seo_title: "",
        seo_keywords: "",
        seo_description: "",
        title: "",
        path: "",
        pinyin: "",
        mid: 0,
        description: "",
        url: "",
        sort: 0,
        type: CategoryType.column,
        target: CategoryTarget.current,
        status: CategoryStatus.SHOW,
      },
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "名称长度在 2 到 10 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    allPath() {
      if (this.params.path == "") {
        return this.params.path + "/" + this.params.pinyin;
      } else {
        return this.params.path + this.params.pinyin;
      }
    },
  },
  created() {
    this.query();
    this.modelList();
  },
  watch: {
    "params.title": function (newv, oldv) {
      this.params.pinyin = pinyin(newv, { toneType: "none" }).replace(
        /\s+/g,
        ""
      );
    },
  },

  methods: {
    handleClick(tab: { index: number; }) {
      this.activeIndex = tab.index;
    },
    //查询
    async query() {
      try {
        let res = await client.callApi("category/Find",{pageSize:1000});
        if (res.isSucc) {
          let data = addLabelValue(tree(res.res.list));
          this.cate = res.res.list;
          this.category = [...data];
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleChange(e:any[]) {
      //获取路径
      let path = [] as string[];
      let ids = Object.values(e);
      console.log(ids);
      ids.forEach((item) => {
        this.cate.forEach((sub: { id: number; pinyin: string; }) => {
          if (sub.id == item) {
            path.push("/" + sub.pinyin);
          }
        });
      });

      this.params.path = path.join("") + "/";
      if (e[0] != -1) {
        this.params.pid = e[e.length - 1];
      }
    },

    //模型列表
    async modelList() {
      try {
        let res = await client.callApi("model/List",{pageSize:1000});
        if (res.isSucc) {
          this.modList = res.res.list;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //更新基本信息
    async categoryAdd() {
        let res = await client.callApi("category/Create",this.params);
        if (res.isSucc) {
          ElMessage({
            message: "创建成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
    },

    submit(formName: string|number) {
      this.params.path = this.allPath;
      let refs:any = this.$refs[formName];
      refs.validate((valid: any) => {
        if (valid) {
          this.categoryAdd();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
