
export interface CategoryData{

    id: number
    /**
     * 父级栏目
     */
    pid: number
    /**
     * seo标题
     */
    seo_title: string
    /**
     * seo关键字
     */
    seo_keywords: string
    /**
     * seo描述
     */
    seo_description: string
    /**
     * 栏目名称
     */
    title: string
    /**
     * 栏目标识
     */
    pinyin: string
    /**
     * 栏目路径
     */
    path: string
    /**
     * 栏目描述
     */
    description: string
    /**
     * 栏目类型 1 栏目 2 页面
     */
    type: CategoryType
    /**
     * 网址
     */
    url: string
    /**
     * 排序
     */
    sort: number
    /**
     * 打开方式 1 当前页面打开 2 新页面打开
     */
    target: CategoryTarget
    /**
     * 栏目状态 1 显示 2隐藏
     */
    status: CategoryStatus
    /**
     * 模型id
     */
    mid: number
}
/**
 * 管理员状态
 */
export enum CategoryStatus {
    SHOW = '1',
    HIDE = "2"
}
export enum CategoryTarget{
    current = "1",
    newpage = '2'
}
export enum CategoryType{
    column = "1",
    page = '2'
}