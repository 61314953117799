import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10" }
const _hoisted_2 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        onTabClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "基本信息",
            name: "first"
          }),
          _createVNode(_component_el_tab_pane, {
            label: "SEO设置",
            name: "second"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        ref: "params",
        model: _ctx.params,
        rules: _ctx.paramsRules,
        "label-width": "84px"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_form_item, { label: "上级栏目" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  props: _ctx.categoryProps,
                  "show-all-levels": false,
                  modelValue: _ctx.categorySelected,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.categorySelected) = $event)),
                  options: _ctx.category,
                  onChange: _ctx.handleChange
                }, null, 8, ["props", "modelValue", "options", "onChange"]),
                _createTextVNode(" 不选择为顶级栏目 ")
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "栏目名称",
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.title,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "栏目标识" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.pinyin,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.pinyin) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "栏目路径" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.allPath,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.allPath) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "栏目类型" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.type,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.type) = $event)),
                  label: "1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("栏目")
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.type,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.params.type) = $event)),
                  label: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("单页")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "扩展模型" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.params.mid,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.params.mid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, { label: "0" }, {
                      default: _withCtx(() => [
                        _createTextVNode("基本模型")
                      ]),
                      _: 1
                    }),
                    (_ctx.modList.length > 0)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.modList, (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_radio, {
                            key: index,
                            label: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.model_name) + "模型 ", 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "是否显示" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.status,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.params.status) = $event)),
                  label: "1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("显示")
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.status,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.params.status) = $event)),
                  label: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("隐藏")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "栏目排序" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.sort,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.params.sort) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.activeIndex == 0]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_el_form_item, { label: "栏目描述" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.description,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.params.description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "栏目链接" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.url,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.params.url) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "打开方式" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.target,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.params.target) = $event)),
                  label: "1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("当前页面")
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_radio, {
                  modelValue: _ctx.params.target,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.params.target) = $event)),
                  label: "2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("新页面")
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "seo标题" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_title,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.params.seo_title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "seo关键词" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_keywords,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.params.seo_keywords) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "seo描述" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.seo_description,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.params.seo_description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.activeIndex == 1]
          ]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.submit('params')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ], 64))
}